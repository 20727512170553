import React from 'react';
import ScriptContext from './ScriptContext';
import Header from './Header.jsx';
import Project from './Project.jsx';
import Image from './Image.jsx';
import Footer from './Footer.jsx';

function Current(props) {
  return (
    <ScriptContext.Provider value={{projects: []}}>
      <Header />

      {/* 2024 */}
		  
	 <Project year="2023" name="LNY Dragon" src="thumb.jpg" categories="category-logo category-digital category-other" >
        <Image src="lrg1.jpg" title="Lunar New Year, Year of the Dragon Logo Variation, MGM Resorts, 2024" />
        <Image src="lrg2.jpg" title="Lunar New Year, Year of the Dragon, MGM Resorts, 2024" />
        <Image src="lrg3.jpg" title="Lunar New Year, Year of the Dragon, MGM Resorts, 2024" />
        <Image src="lrg4.jpg" title="Lunar New Year, Year of the Dragon at Bellagio, MGM Resorts, 2024" />
        <Image src="lrg5.jpg" title="Lunar New Year, Year of the Dragon at MGM Grand, MGM Resorts, 2024" />
        <Image src="lrg6.jpg" title="Lunar New Year, Year of the Dragon at MGM Grand, MGM Resorts, 2024" />
        <Image src="lrg7.jpg" title="Lunar New Year, Year of the Dragon at MGM Grand, MGM Resorts, 2024" />
        <Image src="lrg8.jpg" title="Lunar New Year, Year of the Dragon at MGM Grand, MGM Resorts, 2024" />
        <Image src="lrg9.jpg" title="Lunar New Year, Year of the Dragon at ARIA, MGM Resorts, 2024" />
		<Image src="lrg10.jpg" title="Lunar New Year, Year of the Dragon Merchandise, MGM Resorts, 2024" />
      </Project>
		  
	 {/* 2023 */}
	  <Project year="2023" name="MGMLV NYE" src="thumb.jpg" categories="category-dm category-digital category-logo" >
        <Image src="lrg1.jpg" title="MGM Grand New Year&apos;s Eve Full Set, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="MGM Grand New Year&apos;s Eve Direct Mail, MGM Resorts, 2023" />
        <Image src="lrg3.jpg" title="MGM Grand New Year&apos;s Eve Close up Guitar Pick, MGM Resorts, 2023" />
        <Image src="lrg4.jpg" title="MGM Grand New Year&apos;s Eve Close up Envelope Flap, MGM Resorts, 2023" />
        <Image src="lrg5.jpg" title="MGM Grand New Year&apos;s Eve Direct Mail, MGM Resorts, 2023" />
        <Image src="lrg6.jpg" title="MGM Grand New Year&apos;s Eve Menu, MGM Resorts, 2023" />
        <Image src="lrg7.jpg" title="MGM Grand New Year&apos;s Eve Inside Menu, MGM Resorts, 2023" />
        <Image src="lrg8.jpg" title="MGM Grand New Year&apos;s Eve Event Space with Menu, MGM Resorts, 2023" />
        <Image src="lrg9.jpg" title="MGM Grand New Year&apos;s Eve Event Space with Menu, MGM Resorts, 2023" />
      </Project>
		  
	  <Project year="2023" name="Drawings" src="thumb.jpg" categories="category-digital" >
        <Image src="lrg1.jpg" title="Digital Drawings on iPad, Kathryn Watt, 2023" />
        <Image src="lrg2.jpg" title="Digital Drawings on iPad, Kathryn Watt, 2022" />
        <Image src="lrg3.jpg" title="Digital Drawings on iPad, Kathryn Watt, 2021" />
        <Image src="lrg4.jpg" title="Digital Drawings on iPad, Kathryn Watt, 2020" />
      </Project>
		  
	<Project year="2023" name="Mansion Newsletter" src="thumb.jpg" categories="category-digital" >
        <Image src="lrg1.jpg" title="The Mansion Digital Newsletter, MGM Resorts, 2023" />
      </Project>
		  
	  <Project year="2023" name="EXC NYE" src="thumb.jpg" categories="category-dm category-digital category-logo" >
        <Image src="lrg1.jpg" title="Excalibur New Year&apos;s Eve Direct Mail, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="Excalibur New Year&apos;s Eve Concept Menu, MGM Resorts, 2023" />
        <Image src="lrg3.jpg" title="Excalibur New Year&apos;s Eve Digital, MGM Resorts, 2023" />
        <Image src="lrg4.jpg" title="Excalibur New Year&apos;s Eve Digital Invite, MGM Resorts, 2023" />
      </Project>
		  
	<Project year="2023" name="MC Bookmark" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Meeting and Convention Bookmark, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="Meeting and Convention Bookmark, MGM Resorts, 2023" />
      </Project>
		  
	<Project year="2023" name="300K Blackjack" src="thumb.jpg" categories="category-dm category-digital" >
        <Image src="lrg1.jpg" title="300K Blackjack Invitational, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="300K Blackjack Invitational, MGM Resorts, 2023" />
        <Image src="lrg3.jpg" title="300K Blackjack Invitational, MGM Resorts, 2023" />
      </Project>
		  
	<Project year="2023" name="GIFs" src="thumb.jpg" categories="category-digital" >
        <Image src="lrg1.gif" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg1.jpg" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg2.gif" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg2.jpg" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg3.gif" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg3.jpg" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg4.gif" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg4.jpg" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg5.gif" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
		<Image src="lrg5.jpg" title="GIFs for Excalibur social media stories, MGM Resorts, 2023" />
      </Project>
		  
	  <Project year="2023" name="CC Libraries" src="thumb.jpg" categories="category-other category-digital" >
        <Image src="lrg1.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
        <Image src="lrg2.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
        <Image src="lrg3.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
        <Image src="lrg4.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
        <Image src="lrg5.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
        <Image src="lrg6.jpg" title="Took initiative to set up Creative Services CC Libraries, 2023" />
      </Project>
		    
	  <Project year="2023" name="300K Blazing Reels" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="300K Blazing Reels Slot Tournament, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="300K Blazing Reels Slot Tournament, MGM Resorts, 2023" />
      </Project>
		  
	  <Project year="2023" name="Mexican Independence" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="Mexican Independence Day, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="Mexican Independence Day, MGM Resorts, 2023" />
      </Project>
		  
      <Project year="2023" name="Vbros" src="thumb.jpg" categories="category-concept category-logo" >
        <Image src="lrg1.jpg" title="Voltaggio Brothers Pop-up Restaurant Concepting, MGM Resorts, 2023" />
        <Image src="lrg2.jpg" title="Voltaggio Brothers Pop-up Restaurant Concepting, MGM Resorts, 2023" />
        <Image src="lrg3.jpg" title="Voltaggio Brothers Pop-up Restaurant Concepting, MGM Resorts, 2023" />
        <Image src="lrg4.jpg" title="Voltaggio Brothers Pop-up Restaurant Concepting, MGM Resorts, 2023" />
      </Project>
      
      {/* 2022 */}
      <Project year="2022" name="NYE MGMLV Menu" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="2023 Gold ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, Shared Design: Invite: Ryan Chan, Menu: Kathryn Watt, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="2023 Gold ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, Menu Design: Kathryn Watt, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="2023 Gold ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, Menu Design: Kathryn Watt, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="2023 Gold ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, Menu Design: Kathryn Watt, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="NYE MBAY" src="thumb.jpg" categories="category-dm category-logo category-digital" >
        <Image src="lrg1.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg5.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg6.jpg" title="2023 Bronze ADDY Award Winner, Las Vegas: MBAY New Year&apos;s Eve, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="400K Bloom" src="thumb.jpg" categories="category-dm category-logo" >
        <Image src="lrg1.jpg" title="400K Bloom, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="400K Bloom, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="DM Standards" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Direct Mail and Secondaries Standards, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="NYE EXC" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="Excalibur New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Excalibur New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="Excalibur New Year&apos;s Eve, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="Excalibur New Year&apos;s Eve, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="Fun Dungeon" src="thumb.jpg" categories="category-concept category-logo category-digital" >
        <Image src="lrg1.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
        <Image src="lrg5.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
        <Image src="lrg6.jpg" title="Fun Dungeon Concept, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="MBAY Millions Series" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="Mandalay Bay Millions Series, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Mandalay Bay Millions Series, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="Mandalay Bay Millions Series, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="Mandalay Bay Millions Series, MGM Resorts, 2022" />
        <Image src="lrg5.jpg" title="Mandalay Bay Millions Series, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="500K Sizzlin Summer" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="500K Sizzlin&apos; Summer, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="EXC Crowns" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Excalibur Crowns, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Excalibur Crowns, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="Excalibur Crowns, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="48th and Crepe" src="thumb.jpg" categories="category-logo category-digital" >
        <Image src="lrg1.jpg" title="48th and Crepe, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="48th and Crepe, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="48th and Crepe, MGM Resorts, 2022" />
        <Image src="lrg4.jpg" title="48th and Crepe, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="Chocolate Bar Toolkit" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Chocolate Bar Toolkit, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Chocolate Bar Toolkit, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="300K Shamrock Shakin" src="thumb.jpg" categories="category-logo category-dm category-digital" >
        <Image src="lrg1.jpg" title="300K Shamrock Shakin&apos; Spins, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="300K Shamrock Shakin&apos; Spins, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="300K Shamrock Shakin&apos; Spins, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="Star Spangled Weekend" src="thumb.jpg" categories="category-logo category-other category-digital" >
        <Image src="lrg1.jpg" title="Star Spangled Weekend, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="Star Spangled Weekend, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="Star Spangled Weekend, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="HERE Sales Kit" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="YOU HAD TO BE HERE Sales Kit, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="One Half Million Blackjack" src="thumb.jpg" categories="category-logo category-dm" >
        <Image src="lrg1.jpg" title="$1.5 Million Blackjack, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="Concert Series" src="thumb.jpg" categories="category-logo category-dm category-digital" >
        <Image src="lrg1.jpg" title="Concert Series, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="EXC Pins" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Excalibur Pins, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="MGM Slot Series Booklet" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="MGM Slot Series Booklet, MGM Resorts, 2022" />
        <Image src="lrg2.jpg" title="MGM Slot Series Booklet, MGM Resorts, 2022" />
        <Image src="lrg3.jpg" title="MGM Slot Series Booklet, MGM Resorts, 2022" />
      </Project>
		  
      <Project year="2022" name="Tropic Like Its Hot Logo" src="thumb.jpg" categories="category-logo" >
        <Image src="lrg1.jpg" title="Tropic Like It&apos;s Hot Logo, MGM Resorts, 2022" />
      </Project>

      {/* 2021 */}
      <Project year="2021" name="MGMLV NYE" src="thumb.jpg" categories="category-dm category-digital category-other" >
        <Image src="lrg1.jpg" title="2022 Bronze ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, MGM Resorts, 2021" />
        <Image src="lrg2.jpg" title="2022 Bronze ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, MGM Resorts, 2021" />
        <Image src="lrg3.jpg" title="2022 Bronze ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, MGM Resorts, 2021" />
        <Image src="lrg4.jpg" title="2022 Bronze ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, MGM Resorts, 2021" />
        <Image src="lrg5.jpg" title="2022 Bronze ADDY Award Winner, Las Vegas: MGMLV New Year&apos;s Eve, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="NYNY NYE" src="thumb.jpg" categories="category-concept" >
        <Image src="lrg1.jpg" title="NYNY New Year&apos;s Eve, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="Coaster-Arcade Rebrand" src="thumb.jpg" categories="category-concept category-logo category-digital category-other" >
        <Image src="lrg1.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg2.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg3.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg4.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg5.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg6.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg7.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg8.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg9.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
        <Image src="lrg10.jpg" title="Coaster-Arcade Rebrand, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="AC Logo" src="thumb.jpg" categories="category-digital category-logo" >
        <Image src="lrg1.jpg" title="AC Logo, Ann C., 2021" />
        <Image src="lrg2.jpg" title="AC Logo, Ann C., 2021" />
      </Project>
      <Project year="2021" name="300K Its Raining Freeplay" src="thumb.jpg" categories="category-dm" >
        <Image src="lrg1.jpg" title="300K It&apos;s Raining Freeplay, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="Luxury Meeting District Logo" src="thumb.jpg" categories="category-concept category-logo" >
        <Image src="lrg1.jpg" title="Luxury Meeting District Logo, MGM Resorts, 2021" />
        <Image src="lrg2.jpg" title="Luxury Meeting District Logo, MGM Resorts, 2021" />
        <Image src="lrg3.jpg" title="Luxury Meeting District Logo, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="100K Grand Slam" src="thumb.jpg" categories="category-dm category-digital category-logo" >
        <Image src="lrg1.jpg" title="100K Grand Slam, MGM Resorts, 2021" />
        <Image src="lrg2.jpg" title="100K Grand Slam, MGM Resorts, 2021" />
        <Image src="lrg3.jpg" title="100K Grand Slam, MGM Resorts, 2021" />
        <Image src="lrg4.jpg" title="100K Grand Slam, MGM Resorts, 2021" />
      </Project>
      <Project year="2021" name="DLJ Logo" src="thumb.jpg" categories="category-concept" >
        <Image src="lrg1.jpg" title="DLJ Logo, Matthew W., 2021" />
        <Image src="lrg2.jpg" title="DLJ Logo, Matthew W., 2021" />
      </Project>
      <Project year="2021" name="Gwendolyn" src="thumb.jpg" categories="category-other category-digital" >
        <Image src="lrg1.jpg" title="Gwendolyn, Kimberly Z., 2021" />
        <Image src="lrg2.jpg" title="Gwendolyn, Kimberly Z., 2021" />
      </Project>
      <Project year="2021" name="HGS Invite" src="thumb.jpg" categories="category-concept category-digital category-other" >
        <Image src="lrg1.jpg" title="HGS Invite, MGM Resorts, 2021" />
        <Image src="lrg2.jpg" title="HGS Invite, MGM Resorts, 2021" />
        <Image src="lrg3.jpg" title="HGS Invite, MGM Resorts, 2021" />
        <Image src="lrg4.jpg" title="HGS Invite, MGM Resorts, 2021" />
        <Image src="lrg5.jpg" title="HGS Invite, MGM Resorts, 2021" />
        <Image src="lrg6.jpg" title="HGS Invite, MGM Resorts, 2021" />
      </Project>

      {/* 2020 */}
      <Project year="2020" name="LM Wedding" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Lizette and Matthew&apos;s Wedding, Lizette and Matthew, 2020" />
      </Project>
      <Project year="2020" name="MGMRI Annual Report" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="MGMRI Annual Report, MGM Resorts, 2020" />
        <Image src="lrg2.jpg" title="MGMRI Annual Report, MGM Resorts, 2020" />
      </Project>
      <Project year="2020" name="Vaccinated Pin" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Vaccinated Pin, Brian W., 2020" />
        <Image src="lrg2.jpg" title="Vaccinated Pin, Brian W., 2020" />
      </Project>
      <Project year="2020" name="Chairman Cup Logo" src="thumb.jpg" categories="category-logo" >
        <Image src="lrg1.jpg" title="Chairman&apos;s Cup Logo, MGM Resorts, 2020" />
        <Image src="lrg2.jpg" title="Chairman&apos;s Cup Logo, MGM Resorts, 2020" />
      </Project>
      <Project year="2020" name="200K Festive Fortunes" src="thumb.jpg" categories="category-dm category-logo" >
        <Image src="lrg1.jpg" title="200K Festive Fortunes, MGM Resorts, 2020" />
      </Project>
      <Project year="2020" name="Big Game 55" src="thumb.jpg" categories="category-dm category-concept" >
        <Image src="lrg1.jpg" title="Big Game 55, MGM Resorts, 2020" />
      </Project>
      <Project year="2020" name="MGP Annual Report" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="MGP Annual Report, MGM Resorts, 2020" />
        <Image src="lrg2.jpg" title="MGP Annual Report, MGM Resorts, 2020" />
      </Project>

      {/* 2019 */}
      <Project year="2019" name="EXC NYE" src="thumb.jpg" categories="category-dm category-logo" >
        <Image src="lrg1.jpg" title="EXC New Year&apos;s Eve, MGM Resorts, 2019" />
        <Image src="lrg2.jpg" title="EXC New Year&apos;s Eve, MGM Resorts, 2019" />
      </Project>
      <Project year="2019" name="FI Logo" src="thumb.jpg" categories="category-logo" >
        <Image src="lrg1.jpg" title="FI Logo, Matthew W., 2019" />
        <Image src="lrg2.jpg" title="FI Logo, Matthew W., 2019" />
      </Project>

      <Footer />
    </ScriptContext.Provider>
  );
}

export default Current;
