import { useContext } from 'react';
import ScriptContext from './ScriptContext';
import useScript from './useScript';

function Footer(props) {
  let value = useContext(ScriptContext);

  var text = "";
  value.projects.forEach((element) => text += ("$('#" + element.label + "').magnificPopup("+JSON.stringify(element.config)+");\n"));
  useScript(text);
    
  return null;
}

export default Footer;
