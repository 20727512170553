import React from 'react';
import ScriptContext from './ScriptContext';
import Header from './Header.jsx';
import Project from './Project.jsx';
import Image from './Image.jsx';
import Footer from './Footer.jsx';
import { useEffect } from 'react';

function Archive(props) {

  useEffect(() => { // Scroll when archive button is pushed and Archive is mounted
    document.getElementById("archive").scrollIntoView({ // Note: the archive id is in App.jsx
        behavior: "smooth",
        block: "start",
        inline: "nearest"
    });
  });

  return (
	    <ScriptContext.Provider value={{projects: []}}>
      <Header />

	 {/* 2019 */}	  
	<Project year="2019" name="Maritsa Book Cover" src="thumb.jpg" categories="category-other category-concept" >
        <Image src="lrg1.jpg" title="Maritsa Book Cover, &quot;My Life, My Stories&quot;, 2019" />
        <Image src="lrg2.jpg" title="Maritsa Book Cover, &quot;My Life, My Stories&quot;, 2019" />
        <Image src="lrg3.jpg" title="Maritsa Book Cover, &quot;My Life, My Stories&quot;, 2019" />
        <Image src="lrg4.jpg" title="Maritsa Book Cover, &quot;My Life, My Stories&quot;, 2019" />
      </Project>
		  
    {/* 2018 */}
      <Project year="2018" name="400K Blackjack" src="thumb.jpg" categories="category-logo category-dm" >
        <Image src="lrg1.jpg" title="400K Blackjack, MGM Resorts, 2018" />
      </Project>
		  
	<Project year="2018" name="Knox Bar" src="thumb.jpg" categories="category-concept category-digital" >
        <Image src="lrg1.jpg" title="Knox Bar, MGM Resorts, 2018" />
    </Project>
	
	{/* 2017 */}
	<Project year="2017" name="Corporate Brochure" src="thumb.jpg" categories="category-other" >
        <Image src="lrg1.jpg" title="Corporate Brochure, MGM Resorts, 2017" />
		<Image src="lrg2.jpg" title="Corporate Brochure, MGM Resorts, 2017" />
    </Project>
    <Project year="2017" name="MGMRI Art and Culture" src="thumb.jpg" categories="category-concept category-logo" >
        <Image src="lrg1.jpg" title="MGMRI Art and Culture Logo Mockup, MGM Resorts, 2017" />
		<Image src="lrg2.jpg" title="MGMRI Art and Culture Logo Mockup, MGM Resorts, 2017" />
		<Image src="lrg3.jpg" title="MGMRI Art and Culture Logo Variation, MGM Resorts, 2017" />
		<Image src="lrg4.jpg" title="MGMRI Art and Culture Creative Mockup, MGM Resorts, 2017" />
    </Project>
		  
	{/* 2012 */}
    <Project year="2012" name="ACC Wolverine" src="thumb.jpg" categories="category-other" >
		<Image src="lrg1.jpg" title="Wolverine Charcoal Drawing, 2012" />
    </Project>
	<Project year="2012" name="ACC Drawing" src="thumb.jpg" categories="category-other" >
		<Image src="lrg1.jpg" title="Underground Adventures Pencil Drawing, 2012" />
    </Project>

      <Footer />
    </ScriptContext.Provider>
   );
}

export default Archive;
