import { useEffect } from 'react';

const useScript = (text) => {
  useEffect(() => {
    const script = document.createElement('script');
    script.innerHTML = text;
    document.body.appendChild(script);
    return () => {
      document.body.removeChild(script);
    }
  }, [text]);
};

export default useScript;