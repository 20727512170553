import React from 'react';
import Current from './Current.jsx';
import Archive from './Archive.jsx';
import { useState } from 'react';

function App() {

  const [current, setCurrent] = useState(true)

  function handleClick(e) {
    e.preventDefault();
    current ? setCurrent(false) : setCurrent(true);
  }

  return (
    <div className="text-center">
      <div className="container-fluid">
        <div className="row">
          <Current />
        </div>
        <button id="archive" type="button" className="btn btn-light m-3 border" onClick={handleClick}>{ current ? 'Show More Creative' : 'Show Less Creative' }</button>
        <div className="row">
          { current ? null : <Archive /> }
        </div>
      </div>
    </div>
  );
}

export default App;
