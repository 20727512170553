import { useContext } from 'react';
import ScriptContext from './ScriptContext';

function Image(props) {
  let value = useContext(ScriptContext);

  const projectIndex = value.projects.length-1;
  const year = value.projects[projectIndex].year;
  const name = value.projects[projectIndex].name;
  const fqsrc = "/images/projects/"+year+"/"+name.replace(/\s/g, "%20")+"/"+props.src;

  const index = value.projects[projectIndex].config.items.findIndex((element) => element.src === fqsrc);
  if (index < 0) {
    value.projects[projectIndex].config.items.push({
      src: fqsrc,
      title: props.title
    });
  }
    
  return null;
}

export default Image;
