import React from 'react';
import { useContext } from 'react';
import ScriptContext from './ScriptContext';

function Project(props) {
  const label = "open-popup-"+props.year+"-"+props.name.replace(/\s/g, "-");
  const fqsrc = "/images/projects/"+props.year+"/"+props.name.replace(/\s/g, "%20")+"/"+props.src;

  let value = useContext(ScriptContext);

  const index = value.projects.findIndex((element) => element.year === props.year && element.name === props.name);
  if (index < 0) {
    value.projects.push({
      year: props.year,
      name: props.name,
      src: props.src,
      categories: props.categories,
      label: label,
      fqsrc: fqsrc,
      config: {
        fixedContentPos: true,
        items: [],
        gallery: {
          enabled: true
        },
        type: 'image'
      }
    });
  }
    
  return (
    <div className={"col-md-6 project-item filter " + props.categories}>
      <a href="#top" id={label}>
        <img className="img-fluid" src={fqsrc} alt=""/>
        {props.children}
      </a>
    </div>
  );
}

export default Project;
